<template>
  <div class="max-w-[1500px] relative mx-auto">
    <Header/>
    <!--    Desktop-->

    <div class="mx-auto max-w-[95%] sm:max-w-[90%] md:max-w-[1500px]" v-if="banner_top && !mobile">
      <swiper
          class="md:mt-[76px] mt-0 mb-2"
          :modules="[Navigation, Scrollbar, A11y, Autoplay]"
          :slides-per-view="1"
          :space-between="50"
          :allowTouchMove="false"
          :autoplay="{delay: 10000}"
          :loop="true"
      >
        <template v-for="(item,index) in banners">
          <swiper-slide>
            <div class="md:h-[100px] max-w-[1500px] mx-auto px-2">
              <NuxtLink :href="item.link" target="_blank">
                <template v-if="index<1">
                  <nuxt-img
                      preload
                      width="1500"
                      height="100"
                      format="webp"
                      :alt="item.title"
                      :src="siteURL + item.src"
                      class="w-full h-full object-contain"/>
                </template>
                <template v-else>
                  <nuxt-img
                      width="1500"
                      height="100"
                      format="webp"
                      :alt="item.title"
                      :src="siteURL + item.src"
                      class="w-full h-full object-contain"/>
                </template>
              </NuxtLink>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>
    <!--    Mobile-->

    <div class="mx-auto max-w-[95%] sm:max-w-[90%] md:max-w-[1500px]" v-if="banner_top && mobile">
      <swiper
          class="md:mt-[76px] mt-0 mb-2 md:mb-10"
          :modules="[Navigation, Scrollbar, A11y, Autoplay]"
          :slides-per-view="1"
          :space-between="50"
          :allowTouchMove="false"
          :autoplay="{delay: 10000}"
          :loop="true"
      >
        <template v-for="(item,index) in banners">
          <swiper-slide v-if="item.src_mobile">
            <div class="md:h-[120px] max-w-[1140px] mx-auto">
              <NuxtLink :href="item.link" target="_blank">
                <template v-if="index<1">
                  <nuxt-img
                      preload
                      width="640"
                      height="120"
                      format="webp"
                      :alt="item.title"
                      :src="siteURL + item.src_mobile"
                      class="w-full h-full object-contain"/>
                </template>
                <template v-else>
                  <nuxt-img
                      width="640"
                      height="120"
                      format="webp"
                      :alt="item.title"
                      :src="siteURL + item.src_mobile"
                      class="w-full h-full object-contain"/>
                </template>
              </NuxtLink>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>

    <div class="max-w-[1500px] lg:w-8/9 mx-auto px-2 mt-[40px] sm:mt-[50px] main-body-nh md:mt-0">
      <slot >

      </slot>
    </div>
    <!--    Desktop-->

    <div class="mx-auto max-w-[95%] sm:max-w-[90%] md:max-w-[1500px]" v-if="banner_bottom && !mobile">
      <swiper
          class="md:mt-[76px] mt-0 mb-2"
          :modules="[Navigation, Scrollbar, A11y, Autoplay]"
          :slides-per-view="1"
          :space-between="50"
          :allowTouchMove="false"
          :autoplay="{delay: 10000}"
          :loop="true"
      >
        <template v-for="(item,index) in banners">
          <swiper-slide>
            <div class="md:h-[100px] max-w-[1500px] mx-auto px-2">
              <NuxtLink :href="item.link" target="_blank">
              <template v-if="index<1">
                <nuxt-img
                    preload
                    width="1500"
                    height="100"
                    format="webp"
                    :alt="item.title"
                    :src="siteURL + item.src"
                    class="w-full h-full object-contain"/>
              </template>
              <template v-else>
                <nuxt-img
                    width="1500"
                    height="100"
                    format="webp"
                    :alt="item.title"
                    :src="siteURL + item.src"
                    class="w-full h-full object-contain"/>
              </template>
              </NuxtLink>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>
    <!--    Mobile-->

    <div class="mx-auto max-w-[95%] sm:max-w-[90%] md:max-w-[1500px]" v-if="banner_bottom && mobile">
      <swiper
          class="md:mt-[76px] mt-0 mb-2 md:mb-10"
          :modules="[Navigation, Scrollbar, A11y, Autoplay]"
          :slides-per-view="1"
          :space-between="50"
          :allowTouchMove="false"
          :autoplay="{delay: 10000}"
          :loop="true"
      >
        <template v-for="(item,index) in banners">
          <swiper-slide v-if="item.src_mobile">
            <div class="md:h-[120px] max-w-[1140px] mx-auto">
              <NuxtLink :href="item.link" target="_blank">
              <template v-if="index<1">
                <nuxt-img
                    preload
                    width="640"
                    height="120"
                    format="webp"
                    :alt="item.title"
                    :src="siteURL + item.src_mobile"
                    class="w-full h-full object-contain"/>
              </template>
              <template v-else>
                <nuxt-img
                    width="640"
                    height="120"
                    format="webp"
                    :alt="item.title"
                    :src="siteURL + item.src_mobile"
                    class="w-full h-full object-contain"/>
              </template>
              </NuxtLink>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>

    <Footer/>

  </div>
</template>

<script setup>
import {computed, ref, watch} from 'vue';
import {http} from "~/utils/http";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Scrollbar, A11y, Autoplay,} from "swiper";
import {Page} from "~/composables/nhTypes";
import {useHead} from "nuxt/app";
import {useSiteURL,useSiteTitle} from "../composables/state";

const siteTitle = useSiteTitle();
const banners = ref([]);
const page = ref(new Page());
const banner_top = ref(false);
const banner_bottom = ref(false);
const route = useRoute();
const path = computed(() => route.path.substring(1))
const siteURL = useSiteURL();


const mobile = computed(() => {
  if (process.client) {
    return window.innerWidth < 770;
  } else {
    return  false;
  }
})

  useHead({
    title: computed(() => page.value.meta_title? (page.value.meta_title + ' - ' + siteTitle.value):(page.value.name + ' - ' + siteTitle.value)),
    meta: [
      {name: 'description', content: computed(() => page.value.meta_description)},
      {name: 'keywords', content: computed(() => page.value.meta_keywords)},
      { property: 'og:title', content: computed(() =>   page.value.meta_title? (page.value.meta_title + ' - ' + siteTitle.value):(page.value.name + ' - ' + siteTitle.value)) },
      { property: 'og:description', content: computed(() => page.value.meta_description) },
      { property: 'og:url', content:computed(() =>route.fullPath) },
    ],
  })

const getPages = async () => {
  banner_bottom.value = false;
  banner_top.value = false;

  let n = path.value.indexOf('/');
  let short_path = '';
  if (n > 0) {
    short_path = path.value.substring(0, n);
  } else {
    short_path = path.value;
  }

  try {
    await http
        .get("/api/pages/get-page", {
          params: {
            permalink: short_path,
          }
        })
        .then((response) => {
          page.value = response.data.page;
          if (page.value != null) {
            if (response.data.page.id) {
              getBanners(page.value.id);
            }
            if (page.value.banner_position === 2) {
              banner_bottom.value = true;
            } else if (page.value.banner_position === 3) {
              banner_top.value = true;
            } else if (page.value.banner_position === 4) {
              banner_bottom.value = true;
              banner_top.value = true;
            }
          }
          else{
            page.value = new Page();
          }
        });
  } catch (e) {
  }
}

const getBanners = async (id) => {
  try {
    await http
        .get("/api/banners/get-banners-front", {
          params: {
            page_id: id
          }
        })
        .then((response) => {
          response.data.banners.forEach((banner) => {
            banners.value.push({
              pages: banner.pages,
              title: banner.title,
              link: banner.link,
              src: '/storage/images/banners/' + banner.path + '/' + banner.image,
              src_mobile:  banner.image_mobile? '/storage/images/banners/' + banner.path + '/' + banner.image_mobile:null,
            })
          })
        });
  } catch (e) {
  }
}

await getPages();

watch(path, async value => {
  await getPages();
})
</script>

<style>
.main-body-nh{
  min-height: calc( 100vh - 720px);
}
</style>
